import { codeFormKeyDownHandler } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import ActionButton from 'components/ActionButton';
import SubmitIcon from 'components/Icons/SubmitIcon';
import SubmitButton from 'components/SubmitButton';
import { CodeStatus } from 'modules/corporateOffer/types';
import theme from 'theme/theme';

import ActionWarning from '../../components/ActionWarning';
import { SubmitButtonContainer } from '../../components/styledElements/StyledButtons';
import {
  ChatActionsColContainer,
  FormCodeContainer,
  FormInput,
  FormInputCode,
  FormLabel,
} from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

import { FIELDS, TEMPLATE_INPUT_ID } from './PinCode';

type Props = {
  label: string;
  value?: string;
  isEditing?: boolean;
  goToNextStep: (value: string) => void;
};

const Passcode = ({ label, value, isEditing, goToNextStep }: Props) => {
  const { scrollChat } = useChatContext();

  const {
    register,
    watch,
    setValue,
    setFocus,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [active, setActive] = useState(false);
  const [verifyCodeStatus, setVerifyCodeStatus] = useState<CodeStatus>(
    CodeStatus.Pending
  );
  const allFieldsValues = watch();

  const changeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const isNumber = !isNaN(Number(value));

    if ((isNumber || !value) && value !== ' ') {
      setValue(name, value);
    }

    if (value && isNumber && value !== ' ') {
      if (index !== FIELDS.length - 1) {
        setFocus(FIELDS[index + 1]);
      } else {
        e.target.blur();
      }
    }

    if (verifyCodeStatus === CodeStatus.Invalid) {
      setVerifyCodeStatus(CodeStatus.Pending);
    }
  };

  const requestCondition = useMemo(() => {
    return FIELDS.every(
      (field) =>
        !isNaN(Number(allFieldsValues[field])) &&
        allFieldsValues[field] !== '' &&
        verifyCodeStatus !== CodeStatus.Valid
    );
  }, [verifyCodeStatus, allFieldsValues]);

  const formSubmit = () => {
    if (requestCondition) {
      const passwordValue = FIELDS.map((field) => allFieldsValues[field]).join(
        ''
      );

      goToNextStep(passwordValue);
    }
  };

  useEffect(() => {
    scrollChat();
  }, [scrollChat]);

  useEffect(() => {
    if (value) {
      FIELDS.forEach((field, i) => {
        allFieldsValues[field] = value[i];
      });
      setActive(true);
    }
    // removed dependencies, because of extra calling we sent wrong passcode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Warning text="Enter numbers only" />
      <ChatActionsColContainer>
        <FormCodeContainer
          $isActive={active}
          onSubmit={handleSubmit(formSubmit)}
        >
          <FormLabel
            htmlFor={TEMPLATE_INPUT_ID}
            $active={active}
            $isEditing={isEditing}
            $error={verifyCodeStatus === CodeStatus.Invalid}
          >
            {label}
          </FormLabel>
          {!active && (
            <FormInput
              id={TEMPLATE_INPUT_ID}
              type="email"
              {...register(TEMPLATE_INPUT_ID, {
                required: false,
              })}
              onFocus={() => {
                setActive(true);
              }}
              $error={false}
            />
          )}
          {active && (
            <>
              {FIELDS.map((field, i) => (
                <FormInputCode
                  data-testid="passcode-offer-value"
                  key={field}
                  id={field}
                  disabled={verifyCodeStatus === CodeStatus.Valid}
                  type="text"
                  autoComplete="off"
                  {...register(field, {
                    required: false,
                  })}
                  $verificationCodeState={verifyCodeStatus}
                  maxLength={1}
                  value={
                    !isNaN(allFieldsValues[field]) ? allFieldsValues[field] : ''
                  }
                  onKeyDown={(e) =>
                    codeFormKeyDownHandler(
                      e,
                      i,
                      setFocus,
                      FIELDS,
                      allFieldsValues[i + 1]
                    )
                  }
                  onSelect={(e) => e.currentTarget.select()}
                  autoFocus={i === 0}
                  onChange={(e) => changeInputHandler(e, i)}
                />
              ))}
            </>
          )}
          <SubmitButtonContainer>
            <ActionButton
              iconColor={
                !active || requestCondition
                  ? theme.colors.funtainBlue
                  : theme.colors.gray
              }
              backColor="none"
              borderColor="none"
              borderSize="sm"
              buttonSize="sm"
              disabled={!!errors[TEMPLATE_INPUT_ID]}
              icon={SubmitIcon}
              isTypeSubmit
            />
          </SubmitButtonContainer>
        </FormCodeContainer>
        <SubmitButton
          buttonSize="md"
          borderSize="md"
          text="Next"
          fontSize="sm"
          clickHandler={() => formSubmit()}
          isDisabled={!!errors[TEMPLATE_INPUT_ID] || !active}
        />
      </ChatActionsColContainer>
    </>
  );
};

const Warning = styled(ActionWarning)`
  position: static;
  transform: none;
`;

export default Passcode;
