import config from 'config';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import SubmitButton from 'components/SubmitButton';
import { SignUpPlan } from 'modules/corporateOffer/types';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

import SectionTitle from './SectionTitle';

const PricingSection = () => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints.down('md'));

  const buttonText = `TRY FOR FREE${isMobile ? '!' : ''}`;

  const handleSignUpClick = () => {
    window.open(
      `${config.shidoWebsiteVersion}/sign-up?plan=${SignUpPlan.MONTHLY}`,
      '_blank'
    );
  };

  return (
    <Container>
      <SectionTitle>Start your free 1 month trial today</SectionTitle>
      <Subtitle>No commitment. Cancel anytime.</Subtitle>

      <SubmitButton
        isDisabled={false}
        buttonSize={isMobile ? 'sm' : 'lg'}
        fontSize={isMobile ? 'sm' : 'lg'}
        borderSize={isMobile ? 'sm' : 'lg'}
        text={buttonText}
        clickHandler={handleSignUpClick}
      />
    </Container>
  );
};

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  margin-bottom: 34px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
  }
`;

const Container = styled.section`
  max-width: 1400px;
  padding: 0 0 78px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 0 34px;
  }
`;

export default PricingSection;
