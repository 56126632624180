import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isMobile } from 'helpers/getDeviceInfo';
import AffiliateFooter from 'modules/affiliateOffer/components/AffiliateFooter';
import Loader, {
  LoaderWrapper,
} from 'modules/corporateOffer/components/Loader';
import SuccessStoriesSection from 'modules/corporateOffer/components/sections/SuccessStoriesSection';

import CheckoutSection from '../../modules/affiliateOffer/components/CheckoutSection';
import EmailSection from '../../modules/affiliateOffer/components/EmailSection';
import IntroSection from '../../modules/affiliateOffer/components/IntroSection';
import WellnessSection from '../../modules/affiliateOffer/components/WellnessSection';
import { useGetAffiliateOffer } from '../../modules/affiliateOffer/hooks';
import useCreateRefsForClick from '../../modules/affiliateOffer/hooks/useCreateRefsForClick';
import { ConversionFunnel } from '../../modules/affiliateOffer/types';

export const Affiliate = () => {
  const {
    handleRefClick,
    refs: [successRef, checkoutRef],
  } = useCreateRefsForClick();

  const { code } = useParams<{ code: string }>();

  const { data: offerData } = useGetAffiliateOffer(code);

  if (!offerData) {
    return (
      <LoaderWrapper>
        <Loader bigCircleSize={85} smallCircleSize={63} />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <IntroSection
          image={offerData.imageUrl}
          title={offerData.affiliateStatement}
          intro={offerData.intro}
          body={offerData.bodyText}
          isMobile={isMobile}
          onTryForFreeClick={handleRefClick(checkoutRef)}
        />
        <WellnessSection
          goToNextSection={handleRefClick(successRef)}
          isMobile={isMobile}
          videoSrc="/Shido_Explainer_Consumer.mp4"
          videoText="How Shido works : 1.34 min"
        />
        <SuccessStories
          ref={successRef}
          title="Trusted by 1000’s of people like you"
          withSubtitle={false}
        />
        {offerData.conversionFunnel === ConversionFunnel.App ? (
          <EmailSection
            color={offerData.backgroundColour}
            image={offerData.imageUrl}
            ref={checkoutRef}
            isMobile={isMobile}
            publicCode={code}
          />
        ) : (
          <CheckoutSection
            ref={checkoutRef}
            publicCode={offerData.publicCode}
            isMobile={isMobile}
          />
        )}
        <AffiliateFooter />
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

const SuccessStories = styled(SuccessStoriesSection)`
  padding: 78px 0 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0 0;
  }
`;
