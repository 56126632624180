import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Block, Inline } from '@contentful/rich-text-types';

export const HeadingNode = (node: Block | Inline, children: ReactNode) => (
  <>
    <Underline />
    <Heading>{children}</Heading>
  </>
);

export const Heading6Node = (node: Block | Inline, children: ReactNode) => (
  <>
    <Underline $noHide />
    <Heading>{children}</Heading>
  </>
);

const Heading = styled.h2`
  font-size: 25px;
  font-weight: bold;
  margin: 20px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    margin: 28px 0;
  }
`;

const Underline = styled.span<{ $noHide?: boolean }>`
  height: 3px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 45px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: block;

  &:first-child {
    display: ${({ $noHide }) => ($noHide ? 'block' : 'none')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 60px;
    margin-bottom: 37px;
  }
`;

export const ParagraphNode = (node: Block | Inline, children: ReactNode) => (
  <Paragraph>{children}</Paragraph>
);

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 18px;
  font-weight: 300;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5em;
  }
`;

export const LinkNode = (node: Block | Inline, children: ReactNode) => (
  <Link href={node.data.uri}>{children}</Link>
);

const Link = styled.a`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const BoldTextNode = (text: ReactNode) => <BoldText>{text}</BoldText>;

const BoldText = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const ItalicTextNode = (text: ReactNode) => (
  <ItalicText>{text}</ItalicText>
);

const ItalicText = styled.i`
  font-size: 16px;
  font-style: italic;
`;

export const UnorderedlListNode = (
  node: Block | Inline,
  children: ReactNode
) => <UnorderedlList>{children}</UnorderedlList>;

const UnorderedlList = styled.ul`
  && {
    margin-top: -20px;
    font-size: 16px;
    list-style-type: disc;
    padding-inline-start: 4px;
    padding-left: 30px;
    color: ${({ theme }) => theme.colors.primary};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: 5px;
    }
  }
`;

export const OrderedlListNode = (node: Block | Inline, children: ReactNode) => (
  <OrderedList>{children}</OrderedList>
);

const OrderedList = styled.ol`
  font-size: 16px;
  padding-inline-start: 4px;
  color: ${({ theme }) => theme.colors.primary};
  counter-reset: item;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 5px;
  }

  & li {
    & h2::before,
    & p::before {
      counter-increment: item;
      font-weight: 700;
    }

    & p::before {
      content: counters(item, '.') ' ';
    }

    & h2::before {
      content: counters(item, '.') '.';
      margin-right: 20px;
    }
  }
`;

export const ListItemNode = (node: Block | Inline, children: ReactNode) => (
  <ListItem>{children}</ListItem>
);

const ListItem = styled.li`
  & p {
    margin-bottom: 8px;
  }

  & p:last-child {
    padding-bottom: 0;
  }
`;
