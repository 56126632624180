import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import SubmitButton from 'components/SubmitButton';
import { CodeStatus } from 'modules/corporateOffer/types';

import ActionWarning from '../../components/ActionWarning';
import MobileKeyboard from '../../components/MobileKeyboard';
import { DeleteButton } from '../../components/styledElements/StyledButtons';
import {
  ChatActionsContainer,
  FormCodeContainer,
  FormInputCode,
} from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

import { FIELDS } from './PinCode';

type PasscodeMobileProps = {
  goToNextStep: (value: string) => void;
};

const PasscodeMobile = ({ goToNextStep }: PasscodeMobileProps) => {
  const { scrollChat } = useChatContext();

  const { register, watch, setValue, handleSubmit } = useForm();
  const [active, setActive] = useState(false);
  const [verifyCodeStatus, setVerifyCodeStatus] = useState<CodeStatus>(
    CodeStatus.Pending
  );
  const [currentIndex, setCurrentIndex] = useState(1);
  const allFieldsValues = watch();

  const requestCondition = useMemo(() => {
    return FIELDS.every(
      (field) =>
        !isNaN(Number(allFieldsValues[field])) &&
        allFieldsValues[field] !== '' &&
        verifyCodeStatus !== CodeStatus.Valid
    );
  }, [verifyCodeStatus, allFieldsValues]);

  const formSubmit = useCallback(() => {
    if (requestCondition) {
      const passwordValue = FIELDS.map((field) => allFieldsValues[field]).join(
        ''
      );

      goToNextStep(passwordValue);
    }
  }, [requestCondition, goToNextStep, allFieldsValues]);

  const buttonClick = useCallback(
    (number: number) => {
      if (currentIndex <= FIELDS.length) {
        setValue(String(currentIndex), number);

        if (verifyCodeStatus === CodeStatus.Invalid) {
          setVerifyCodeStatus(CodeStatus.Pending);
        }

        if (currentIndex === FIELDS.length) {
          setCurrentIndex(1);
          formSubmit();
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      }
    },
    [currentIndex, formSubmit, setValue, verifyCodeStatus]
  );

  useEffect(() => {
    scrollChat();
  }, [scrollChat]);

  return (
    <ChatActionsContainer $isMobile $isNotAbsolute={active}>
      <FormCodeContainer
        $isActive={active}
        $isMobile
        onSubmit={handleSubmit(formSubmit)}
        role="form"
      >
        {!active && (
          <SubmitButton
            buttonSize="sm"
            borderSize="xs"
            text="Enter passcode"
            fontSize="sm"
            clickHandler={() => {
              setActive(true);
              setTimeout(() => {
                scrollChat();
              }, 100);
            }}
          />
        )}
        {active && (
          <>
            {FIELDS.map((field, i) => (
              <FormInputCode
                key={field}
                id={field}
                disabled={verifyCodeStatus === CodeStatus.Valid}
                type="text"
                autoComplete="off"
                {...register(field, {
                  required: false,
                })}
                $verificationCodeState={verifyCodeStatus}
                maxLength={1}
                value={
                  !isNaN(allFieldsValues[field]) ? allFieldsValues[field] : ''
                }
                $isMobile
                readOnly
                autoFocus={i === 0}
              />
            ))}
          </>
        )}
        {active && <MobileKeyboard buttonClick={buttonClick} />}
        {active && (
          <DeleteButton
            onClick={() => {
              setValue(String(currentIndex - 1), '');
              if (currentIndex > 1) {
                setCurrentIndex(currentIndex - 1);
              }
            }}
          >
            delete
          </DeleteButton>
        )}
      </FormCodeContainer>
      {!active && <ActionWarning text="Enter numbers only" isMobile />}
    </ChatActionsContainer>
  );
};

export default React.memo(PasscodeMobile);
