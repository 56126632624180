import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ErrorMessage } from '@hookform/error-message';
import ActionButton from 'components/ActionButton';
import SmallWarningIcon from 'components/Icons/SmallWarningIcon';
import SubmitIcon from 'components/Icons/SubmitIcon';
import SubmitButton from 'components/SubmitButton';
import theme from 'theme/theme';

import { SubmitButtonContainer } from '../../components/styledElements/StyledButtons';
import {
  ChatActionsColContainer,
  ErrorText,
  FormContainer,
  FormInput,
  FormLabel,
} from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

const NAME_INPUT_ID = 'NAME_INPUT_ID';

type NameProps = {
  isMobile?: boolean;
  isEditing: boolean;
  value?: string;
  goToNextStep: (value: string) => void;
};

const Name = ({ isMobile, goToNextStep, value, isEditing }: NameProps) => {
  const { scrollChat } = useChatContext();

  const {
    register,
    setValue,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm();
  const [active, setActive] = useState(false);
  const nameValue = watch()[NAME_INPUT_ID];

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(NAME_INPUT_ID, value, { shouldValidate: true });
  };

  const formSubmit = () => {
    if (isValid) {
      goToNextStep(nameValue);
    }
  };

  useEffect(() => {
    scrollChat();
  }, [scrollChat]);

  useEffect(() => {
    if (value) {
      setValue(NAME_INPUT_ID, value, { shouldValidate: true });
      setActive(true);
    }
  }, [setValue, value]);

  return (
    <ChatActionsColContainer $isMobile={isMobile} $isNotAbsolute={isMobile}>
      <FormContainer
        $error={!!errors[NAME_INPUT_ID]}
        $isMobile={isMobile}
        onSubmit={handleSubmit(formSubmit)}
      >
        <FormLabel
          htmlFor={NAME_INPUT_ID}
          $active={active}
          $error={!!errors[NAME_INPUT_ID]}
          $isEditing={isEditing}
          $isMobile={isMobile}
        >
          {isMobile ? 'Enter first name' : 'Type your first name'}
        </FormLabel>
        <FormInput
          $isMobile={isMobile}
          id={NAME_INPUT_ID}
          type="text"
          {...register(NAME_INPUT_ID, {
            required: 'This field is required',
          })}
          autoComplete="off"
          onFocus={() => setActive(true)}
          $error={!!errors[NAME_INPUT_ID]}
          onChange={changeInputHandler}
        />
        <ErrorMessage
          name={NAME_INPUT_ID}
          errors={errors}
          render={({ message }) => (
            <ErrorText $error={!!errors[NAME_INPUT_ID]}>
              <SmallWarningIcon color={theme.colors.red} />
              {message}
            </ErrorText>
          )}
        />
        {!isMobile && (
          <SubmitButtonContainer>
            <ActionButton
              iconColor={
                errors[NAME_INPUT_ID]
                  ? theme.colors.gray
                  : theme.colors.funtainBlue
              }
              backColor="none"
              borderColor="none"
              borderSize="sm"
              buttonSize="sm"
              clickHandler={formSubmit}
              disabled={!!errors[NAME_INPUT_ID] || !active}
              icon={SubmitIcon}
              isTypeSubmit
            />
          </SubmitButtonContainer>
        )}
      </FormContainer>
      <NextContainer>
        <SubmitButton
          buttonSize={isMobile ? 'sm' : 'md'}
          borderSize={isMobile ? 'xs' : 'md'}
          text="Next"
          fontSize="sm"
          clickHandler={() => formSubmit()}
          isDisabled={!!errors[NAME_INPUT_ID] || !active}
        />
      </NextContainer>
    </ChatActionsColContainer>
  );
};

export const NextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: fixed;
    bottom: 16px;
  }
`;

export default Name;
