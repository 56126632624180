import React from 'react';
import styled from 'styled-components';

import SectionTitle from 'modules/corporateOffer/components/sections/SectionTitle';
import StickyHeader from 'modules/corporateOffer/components/StickyHeader';
import theme from 'theme/theme';

import HeroSection from './HeroSection';

type Props = {
  image: string | null;
  title: string;
  intro: string;
  body: string;
  isMobile?: boolean;
  onTryForFreeClick: () => void;
};

const IntroSection = ({
  image,
  title,
  intro,
  body,
  isMobile,
  onTryForFreeClick,
}: Props) => (
  <Container>
    <StickyHeader
      right={
        <TryForFree
          $color={theme.colors.white}
          $bgColor={theme.colors.darkBlueV2}
          role="button"
          onClick={onTryForFreeClick}
        >
          Try for free
        </TryForFree>
      }
    />
    <HeroSection title={title} image={image} isMobile={isMobile} />

    <IntroContent>
      <SectionTitle>{intro}</SectionTitle>
      <IntroBody>{body}</IntroBody>
    </IntroContent>
  </Container>
);

const Container = styled.div`
  overflow: hidden;
`;

const TryForFree = styled.span<{
  $color: string;
  $bgColor: string;
}>`
  font-size: 16px;
  color: ${({ $color }) => $color};
  background-color: ${({ $bgColor }) => $bgColor};
  cursor: pointer;
  border-radius: 20px;
  padding: 12px 20px;
  border: none;
  font-weight: 400;
  text-decoration: none;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
`;

const IntroContent = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed rgba(65 78 113 / 25%);
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 220px;
  padding-bottom: 78px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 100px;
    padding-bottom: 34px;
  }
`;

const IntroBody = styled.p`
  color: ${({ theme }) => theme.colors.topicBlue};
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
  margin: 0;
`;

export default IntroSection;
