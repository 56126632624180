import styled, { css } from 'styled-components';

export const InputContainer = styled.div<{
  $isMobile?: boolean;
  $error: boolean;
}>`
  position: relative;
  width: ${({ $isMobile }) => ($isMobile ? '335px' : '300px')};
  height: 60px;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 auto 40px;
  border-radius: 4px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 295px;
    margin-bottom: 24px;
  }
`;

export const FormLabel = styled.label<{
  $error?: boolean;
  $active: boolean;
  $isMobile?: boolean;
}>`
  color: ${({ $active, theme }) =>
    $active ? theme.colors.darkBlue : theme.colors.funtainBlue};
  font-size: ${({ $active }) => ($active ? '11px' : '16px')};
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  top: ${({ $active }) => ($active ? '4px' : '50%')};
  left: ${({ $isMobile }) => ($isMobile ? '10px;' : '20px;')};
  transform: translate(0, ${({ $active }) => ($active ? '0' : '-50%')});
  cursor: ${({ $active }) => ($active ? 'default' : 'text')};
  ${({ $error, theme }) =>
    $error &&
    css`
      color: ${theme.colors.red};
    `};
`;

export const FormInput = styled.input<{
  $error?: boolean;
  $isMobile?: boolean;
}>`
  box-sizing: border-box;
  color: ${({ $error, theme }) =>
    $error ? theme.colors.red : theme.colors.darkBlueV2};
  width: ${({ $error }) => ($error ? '90' : '100')}%;
  height: 100%;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  background: none;
  padding: ${({ $isMobile }) => ($isMobile ? '0 10px;' : '0 20px;')};
`;

export const ErrorText = styled.div<{ $error?: boolean }>`
  position: relative;
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.white} !important;
  visibility: ${({ $error }) => ($error ? 'visible' : 'hidden')};
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const DeleteValueButton = styled.button`
  position: absolute;
  top: 50%;
  left: 260px;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    left: 260px;
  }
`;
