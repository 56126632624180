import React, { forwardRef } from 'react';
import styled from 'styled-components';

import CircleArrowIcon from 'components/Icons/CircleArrowIcon';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';

import { SuccessStoriesCarouselData } from '../SuccessStoriesCarousel/carouselData';
import SuccessStoriesCarousel from '../SuccessStoriesCarousel/SuccessStoriesCarousel';

import { ArrowCircleTemplate } from './HealthImprovementProgramSection';
import SectionTitle from './SectionTitle';
import { DashedLine } from './ShidoPowerSection';

type Props = {
  title?: string;
  className?: string;
  withSubtitle?: boolean;
  withFooter?: boolean;
  onScroll?: () => void;
};

const SuccessStoriesSection = (
  {
    title = 'Success stories',
    withSubtitle = true,
    withFooter = true,
    className,
    onScroll,
  }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  return (
    <Container ref={ref} className={className}>
      <SectionTitle>{title}</SectionTitle>
      {withSubtitle && (
        <Subtitle>
          See what our satisfied users have to say about their journey with
          Shido.
        </Subtitle>
      )}
      <SuccessStoriesCarousel
        isMobile={isMobile}
        slides={SuccessStoriesCarouselData}
      />
      {!!onScroll && withFooter && (
        <ArrowCircleContainer onClick={onScroll}>
          <CircleArrowIcon uniqueID="success-stories-arrow" />
        </ArrowCircleContainer>
      )}
      {withFooter && <DashedLine $withTopMargin={!onScroll} />}
    </Container>
  );
};

const ArrowCircleContainer = styled(ArrowCircleTemplate)`
  margin: 40px auto 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 38px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 330px;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  padding: 78px 0;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0;
  }
`;

export default forwardRef(SuccessStoriesSection);
