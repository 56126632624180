import React, { useState } from 'react';
import styled from 'styled-components';

import SubmitButton from 'components/SubmitButton';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';

import Loader from '../Loader';
import OfferCodeForm from '../OfferCodeForm';

type Props = {
  monthPeriod: string | undefined;
  isLoadingData: boolean;
  isExpired: boolean;
  isSuspended: boolean;
  notFound: boolean;
  withDepartments: boolean;
  setIsExpired: () => void;
  setIsSuspended: () => void;
  onTryForFree: () => void;
};

const HeroSection = ({
  monthPeriod,
  isLoadingData,
  isExpired,
  isSuspended,
  notFound,
  withDepartments,
  setIsExpired,
  setIsSuspended,
  onTryForFree,
}: Props) => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;
  const error = getErrorLabel(isExpired, notFound, isSuspended);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Wrapper>
      <Container>
        <Title style={{ visibility: isLoadingData ? 'hidden' : 'visible' }}>
          {error ? error : `Get ${monthPeriod} premium access for free!`}
        </Title>
        {error && (
          <Subtitle>
            You can still try Shido free for 1 month. No commitment, cancel any
            time.
          </Subtitle>
        )}

        <FormPlaceholder>
          {(isLoadingData || isLoading) && (
            <LoaderWrapper>
              <Loader
                bigCircleSize={isMobile ? 85 : 160}
                smallCircleSize={isMobile ? 63 : 120}
              />
            </LoaderWrapper>
          )}
        </FormPlaceholder>

        <FirstBubble $usePct $left={-7} $top={32} $height={273} $width={273}>
          <BlueBubbleCircle />
        </FirstBubble>
        <SecondBubble $usePct $left={15} $top={60} $height={73} $width={73}>
          <BlueBubbleCircle />
        </SecondBubble>
        <ThirdBubble $usePct $right={-2} $top={28} $height={73} $width={73}>
          <BlueBubbleCircle />
        </ThirdBubble>
        <FourthBubble $usePct $right={14} $top={74} $height={138} $width={138}>
          <BlueBubbleCircle />
        </FourthBubble>
      </Container>
      {!error && (
        <FormWrapper
          $withDepartments={withDepartments}
          style={{
            display: isLoadingData || isLoading ? 'none' : 'block',
          }}
        >
          <OfferCodeForm
            setPageToExpired={setIsExpired}
            setPageToSuspended={setIsSuspended}
            isMobile={isMobile}
            onLoading={setIsLoading}
          />
        </FormWrapper>
      )}
      {error && (
        <TryFreeWrapper>
          <SubmitButton
            isDisabled={false}
            buttonSize={isMobile ? 'sm' : 'xl'}
            fontSize={isMobile ? 'sm' : 'md'}
            borderSize={isMobile ? 'sm' : 'xl'}
            text="try for free"
            clickHandler={onTryForFree}
          />
        </TryFreeWrapper>
      )}
    </Wrapper>
  );
};

const getErrorLabel = (
  isExpired: boolean,
  notFound: boolean,
  isSuspended: boolean
) => {
  if (isExpired) {
    return 'This offer has expired';
  }

  if (notFound || isSuspended) {
    return "We're sorry, this offer no longer exists";
  }

  return '';
};

export const BubbleCircleContainer = styled.div<{
  $width: number;
  $height: number;
  $bottom?: number;
  $right?: number;
  $top?: number;
  $left?: number;
  $opacity?: number;
  $usePct?: boolean;
}>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  bottom: ${({ $bottom, $usePct }) =>
    $usePct ? `${$bottom}%` : `${$bottom}px`};
  top: ${({ $top, $usePct }) => ($usePct ? `${$top}%` : `${$top}px`)};
  right: ${({ $right, $usePct }) => ($usePct ? `${$right}%` : `${$right}px`)};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: ${({ $left, $usePct }) => ($usePct ? `${$left}%` : `${$left}px`)};
  opacity: ${({ $opacity }) => ($opacity !== undefined ? $opacity : 1)};
  position: absolute;
  border: 1px dashed white;
  border-radius: 50%;
  overflow: hidden;
`;

export const BubbleCircle = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  opacity: 0.15;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 70px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.topicBlue};
  clip-path: ellipse(72% 91% at 50% 9%);
  position: relative;
  padding-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    clip-path: ellipse(122% 83% at 50% 9%);
    overflow: hidden;
    padding-bottom: 150px;
  }
`;

const Title = styled.h1`
  font-size: 50px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  margin-top: 65px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 45px;
    line-height: 50px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
  }
`;

const Subtitle = styled.p`
  font-size: 30px;
  line-height: 50px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  margin: 0;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 24px;
    line-height: 35px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
  }
`;

const FormPlaceholder = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 120px;
  }
`;

const FormWrapper = styled.div<{ $withDepartments: boolean }>`
  position: absolute;
  bottom: ${({ $withDepartments }) => ($withDepartments ? '40px' : '136px')};
  left: 0;
  right: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: ${({ $withDepartments }) => ($withDepartments ? '100px' : '180px')};
  }
`;

const TryFreeWrapper = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: 10px;
  }
`;

const LoaderWrapper = styled.div`
  width: 200px;
  height: 200px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 120px;
    height: 120px;
  }
`;

const BlueBubbleCircle = styled(BubbleCircle)`
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

const FirstBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    left: -20%;
    top: 50%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: -40%;
    top: 60%;
  }
`;

const SecondBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

const ThirdBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    right: -6%;
    top: 20%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -10%;
    top: 30%;
  }
`;

const FourthBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    right: 6%;
    top: 70%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -4%;
    top: 75%;
  }
`;

export default HeroSection;
