import React, { SVGProps } from 'react';

type PlayProps = {
  color: string;
  className?: string;
} & SVGProps<SVGSVGElement>;

const Play = ({ color, className, ...props }: PlayProps) => (
  <svg
    width="12"
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    fill={color}
    className={className}
    {...props}
  >
    <path d="M22.62 10.164L4.327 1.136C2.334.152 0 1.603 0 3.826v18.292c0 2.237 2.36 3.687 4.355 2.676l18.292-9.264c2.206-1.117 2.19-4.272-.028-5.366z" />
  </svg>
);

export default Play;
