import { Variants, motion } from 'framer-motion';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import CloseIcon from 'components/Icons/collapse/CloseIcon';
import OpenIcon from 'components/Icons/collapse/OpenIcon';
import theme from 'theme/theme';

const VARIANTS: Variants = {
  open: {
    height: 'auto',
    opacity: 1,
  },
  close: {
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
  },
  initial: {
    height: 0,
    opacity: 0,
    transitionDelay: '0s',
    transitionDuration: '0s',
  },
};

type Props = {
  question: string;
  answer: string;
};

const FAQItem: FC<Props> = ({ answer, question }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container key={question}>
      <Header onClick={() => setIsOpen((prev) => !prev)}>
        <Title>{question}</Title>
        {isOpen ? (
          <CloseIcon color={theme.colors.darkBlueV2} />
        ) : (
          <OpenIcon color={theme.colors.darkBlueV2} />
        )}
      </Header>
      <motion.div
        key="questions"
        variants={VARIANTS}
        initial="initial"
        animate={isOpen ? 'open' : 'close'}
      >
        <Answer>{answer}</Answer>
      </motion.div>
    </Container>
  );
};

const Container = styled.article`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 4px solid ${({ theme }) => theme.colors.darkBlueV2};
  border-radius: 4px;
  padding: 0 20px 10px;
  box-shadow: 0 5px 20px 0 rgba(0 0 0 / 5%);
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-top: 30px;
  padding-bottom: 20px;
  cursor: pointer;

  & svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlueV2};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 23px;
  }
`;

const Answer = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
  white-space: pre-line;
  border-top: 1px solid rgba(65 78 113/ 25%);
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 10px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`;

export default FAQItem;
