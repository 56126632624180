import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

const SectionTitle = ({ children }: Props) => {
  return <Title>{children}</Title>;
};

const Title = styled.h3`
  font-size: 40px;
  line-height: 60px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 23px;
  }
`;

export default SectionTitle;
