import { EntryFieldTypes } from 'contentful';
import { contentfulClient } from 'lib';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import ActionButton from 'components/ActionButton';
import CheckboxIcon from 'components/Icons/CheckboxIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import {
  BoldTextNode,
  HeadingNode,
  ParagraphNode,
  LinkNode,
  UnorderedlListNode,
  ListItemNode,
  Heading6Node,
  ItalicTextNode,
  OrderedlListNode,
} from 'components/StyledRichTextNodes';
import theme from 'theme/theme';

const options: Options = {
  renderMark: {
    [MARKS.BOLD]: BoldTextNode,
    [MARKS.ITALIC]: ItalicTextNode,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: HeadingNode,
    [BLOCKS.HEADING_6]: Heading6Node,
    [BLOCKS.PARAGRAPH]: ParagraphNode,
    [INLINES.HYPERLINK]: LinkNode,
    [BLOCKS.UL_LIST]: UnorderedlListNode,
    [BLOCKS.OL_LIST]: OrderedlListNode,
    [BLOCKS.LIST_ITEM]: ListItemNode,
  },
};

type Props = {
  isMobile?: boolean;
  acceptedTermsAndConditions: boolean;
  acceptedPrivacyPolicy: boolean;
  closePopup: () => void;
  toggleIsAgreePrivacyPolicy: () => void;
  toggleIsAgreeTermsAndConditions: () => void;
};

const TermsAndConditionsPopup = ({
  acceptedTermsAndConditions,
  acceptedPrivacyPolicy,
  isMobile,
  closePopup,
  toggleIsAgreePrivacyPolicy,
  toggleIsAgreeTermsAndConditions,
}: Props) => {
  const [content, setContent] = useState<Document>();
  const popupRef = useRef<HTMLDivElement>(null);

  const getContent = async () => {
    const data = await contentfulClient.getEntry<{
      contentTypeId: 'pages';
      fields: { content: EntryFieldTypes.RichText };
    }>('1n6Y0GuIpCxeu69nqvJSPd', {
      locale: 'en-GB',
    });

    return data.fields.content;
  };

  useEffect(() => {
    const keyDownEvent = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closePopup();
      }
    };

    const clickOutside = (event: MouseEvent) => {
      if (!popupRef?.current?.contains(event.target as Node)) {
        closePopup();
      }
    };

    document.addEventListener('keydown', keyDownEvent);
    document.addEventListener('mousedown', clickOutside);

    (async function () {
      const content = await getContent();

      setContent(content);
    })();

    return () => {
      document.removeEventListener('keydown', keyDownEvent);
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [closePopup]);

  return createPortal(
    <Container>
      <ModalWindow ref={popupRef} $isMobile={isMobile}>
        <Title>Terms of service</Title>
        <Content $isMobile={isMobile}>
          {documentToReactComponents(content as Document, options)}
        </Content>
        <BottomGradientElement />
        <CloseButtonWrapper>
          <ActionButton
            iconColor={theme.colors.white}
            backColor={theme.colors.funtainBlue}
            borderColor={theme.colors.white}
            borderSize="sm"
            buttonSize="sm"
            clickHandler={closePopup}
            icon={CloseIcon}
          />
        </CloseButtonWrapper>
        <Agreements>
          <Agreement>
            <CheckboxContainer onClick={toggleIsAgreeTermsAndConditions}>
              <CheckboxIcon isChecked={acceptedTermsAndConditions} />
            </CheckboxContainer>
            <AgreementText>I agree to the terms and conditions</AgreementText>
          </Agreement>
          <hr />
          <Agreement>
            <CheckboxContainer onClick={toggleIsAgreePrivacyPolicy}>
              <CheckboxIcon isChecked={acceptedPrivacyPolicy} />
            </CheckboxContainer>
            <AgreementText>I agree to the privacy policy</AgreementText>
          </Agreement>
        </Agreements>
      </ModalWindow>
    </Container>,
    document.body
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgb(0 0 0 / 60%);
`;

const ModalWindow = styled.div<{ $isMobile?: boolean }>`
  position: absolute;
  width: 80vw;
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
  box-sizing: border-box;
  border-radius: 5px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 90vw;
    `}
`;

const Title = styled.h4`
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

const Content = styled.div<{ $isMobile?: boolean }>`
  margin: 15px 20px 0;
  height: 65vh;
  overflow-y: auto;
  padding-right: 55px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${({ $isMobile }) => ($isMobile ? '45vh' : '65vh')};
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.lightYellow};
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    height: 40px;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.funtainBlue};
    box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  }
`;

const BottomGradientElement = styled.div`
  width: 100%;
  height: 60px;
  background: linear-gradient(
    rgb(255 255 255 / 0%),
    rgb(255 255 255 / 50%),
    #fff
  );
  position: relative;
  top: -40px;
  margin-right: 10px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  transform: scale(0.6);
`;

const Agreements = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid rgb(0 0 0 / 10%);
  background: ${({ theme }) => theme.colors.white};

  hr {
    display: block;
    margin: 0;
    height: 2px;
    border: 0;
    background-image: url('/images/dashedLine.svg');
    opacity: 0.1;
  }
`;

const Agreement = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.funtainBlue};
  background-color: ${({ theme }) => theme.colors.funtainBlue};
`;

const AgreementText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: 14px;
`;

export default TermsAndConditionsPopup;
