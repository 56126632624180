import React, { forwardRef } from 'react';
import styled from 'styled-components';

import Arrow from 'components/Icons/Arrow';

import FAQItem from './FAQItem';
import SectionTitle from './SectionTitle';

const FAQ = [
  {
    question: 'Is Shido GDPR compliant?',
    answer: `Yes, Shido fully complies with GDPR regulations.\nWe prioritise data privacy and ensure all user information is handled in accordance with legal requirements.`,
  },
  {
    question: 'Is user data secure and encrypted?',
    answer: `Absolutely.\nAll user data is encrypted both in transit and at rest, ensuring it remains safe and secure at all times.`,
  },
  {
    question: 'Can I see employee data?',
    answer: `No, never.\nAn individual's data is strictly private and visible only to them. `,
  },
  {
    question: 'How do employees access Shido?',
    answer: `Employees receive a unique access code and a link to create their account.\nOnce they’ve signed up, they can download the Shido app and access premium features immediately.`,
  },
  {
    question: 'What kind of data is included in the health index report?',
    answer: `The health index report includes anonymised, aggregate data that offers insights into how your\nworkforce is progressing without revealing individual details.`,
  },
  {
    question: 'Does Shido integrate with wearables and existing tools?',
    answer: `Yes, Shido connects seamlessly with popular wearable devices like Fitbit, Google Fit, and HealthKit.\nThis provides employees with a centralised platform for tracking their wellness.`,
  },
  {
    question: 'Is there a minimum number of employees required to use Shido?',
    answer: `No, Shido is scalable and works for organisations of all sizes, from small teams to large corporations.`,
  },
  {
    question: 'How much does it cost?',
    answer: `Our annual plans are designed to suit companies of all sizes, with volume discounts available based on the number of employees.\nGet in touch for a customised quote tailored to your organisation.`,
  },
  {
    question: 'How does Shido benefit my business?',
    answer: `By improving employee well-being, Shido can help boost productivity, reduce absenteeism, and foster a more engaged workforce.\nIt also enhances your company's commitment to health and wellness.`,
  },
  {
    question: 'Can we customise Shido for our company?',
    answer: `Yes, Shido offers flexibility with branding options like a custom landing page and exclusive reports tailored to your organisation’s needs.`,
  },
  {
    question: 'What happens at the end of the free trial?',
    answer: `At the end of the trial period, you can choose to roll out Shido to your team with full premium access. Our team will assist with the transition.`,
  },
  {
    question: 'How often is the health index report provided?',
    answer: `We deliver aggregate and anonymised reports quarterly, providing a regular snapshot of your workforce’s overall wellness progress.`,
  },
  {
    question: 'What if my employees need help with the app?',
    answer: `Shido offers dedicated support for all users. Employees can access help directly through the app or via our support team.`,
  },
  {
    question: 'What happens if an employee leaves the business?',
    answer: `Shido accounts are personal and belong to the individual, not the business.\nWhen you provide premium access to an employee, they retain full use of their account until the access code expires.\n\nIf they leave your organisation, they can continue their Shido journey by purchasing an individual subscription or activating a new access code from their next employer.`,
  },
];

interface Props {
  goToNextSection: () => void;
}

const FAQSection = (
  { goToNextSection }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <Container ref={ref}>
      <SectionTitle>Employee FAQ</SectionTitle>
      <Questions>
        {FAQ.map(({ answer, question }) => (
          <FAQItem key={question} answer={answer} question={question} />
        ))}
      </Questions>
      <ArrowWrapper onClick={goToNextSection}>
        <Arrow />
      </ArrowWrapper>
    </Container>
  );
};

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;
  margin: 30px auto 80px;

  ${({ theme }) => theme.breakpoints.down('xl')} {
    width: auto;
    padding: 0 20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 78px 0;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px dashed rgba(65 78 113 / 25%);

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0;
  }
`;

const ArrowWrapper = styled.div`
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
`;

export default forwardRef(FAQSection);
