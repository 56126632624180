import config from 'config';
import React from 'react';
import styled from 'styled-components';

import SubmitButton from 'components/SubmitButton';
import SectionTitle from 'modules/corporateOffer/components/sections/SectionTitle';

type Props = {
  publicCode: string;
  isMobile: boolean;
};

const CheckoutSection = (
  { publicCode, isMobile }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const handleClick = () => {
    window.open(
      `${config.shidoWebsiteVersion}/sign-up?publicCode=${publicCode}`,
      '_blank'
    );
  };

  return (
    <Container ref={ref}>
      <SectionTitle>Start your free 1 month trial today</SectionTitle>
      <Subtitle>No commitment. Cancel anytime.</Subtitle>
      <SubmitButton
        isDisabled={false}
        buttonSize={isMobile ? 'sm' : 'xl'}
        fontSize={isMobile ? 'sm' : 'md'}
        borderSize={isMobile ? 'sm' : 'xl'}
        text="Try for free"
        clickHandler={handleClick}
      />
    </Container>
  );
};

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  margin-bottom: 34px;
  line-height: 35px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Container = styled.section`
  max-width: 1400px;
  padding: 78px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0;
  }
`;

export default React.forwardRef(CheckoutSection);
