import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionButton from 'components/ActionButton';
import SmallWarningIcon from 'components/Icons/SmallWarningIcon';
import SubmitIcon from 'components/Icons/SubmitIcon';
import SubmitButton from 'components/SubmitButton';
import patterns from 'helpers/patterns';
import theme from 'theme/theme';

import { SubmitButtonContainer } from '../../components/styledElements/StyledButtons';
import {
  ChatActionsColContainer,
  ErrorText,
  FormContainer,
  FormInput,
  FormLabel,
} from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

import { NextContainer } from './Name';

const EMAIL_INPUT_ID = 'EMAIL_INPUT_ID';

const validationSchema = yup.object().shape({
  EMAIL_INPUT_ID: yup
    .string()
    .email('Invalid email format')
    .required('This field is required'),
});

type Props = {
  value?: string;
  isMobile?: boolean;
  isEditing: boolean;
  errorResponse?: { isError: boolean };
  goToNextStep: (value: string) => void;
  setErrorResponse?: (errorResponse: { isError: boolean }) => void;
};

const Email = ({
  isMobile,
  goToNextStep,
  setErrorResponse,
  value,
  errorResponse,
  isEditing,
}: Props) => {
  const { scrollChat } = useChatContext();

  const {
    register,
    setValue,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    setError,
  } = useForm({ resolver: yupResolver(validationSchema) });
  const [active, setActive] = useState(false);
  const emailValue = watch()[EMAIL_INPUT_ID];

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (setErrorResponse) setErrorResponse({ isError: false });

    setValue(EMAIL_INPUT_ID, value, { shouldValidate: true });
  };

  useEffect(() => {
    if (errorResponse && errorResponse.isError) {
      setError(EMAIL_INPUT_ID, {
        message: 'Email exists, please sign in or create a new account',
        type: 'existing email',
      });
    }
  }, [errorResponse, setError]);

  const formSubmit = () => {
    if (isValid) {
      goToNextStep(emailValue);
    }
  };

  useEffect(() => {
    scrollChat();
  }, [scrollChat]);

  useEffect(() => {
    if (value) {
      setValue(EMAIL_INPUT_ID, value, { shouldValidate: true });
      setActive(true);
    }
  }, [setValue, value]);

  return (
    <ChatActionsColContainer $isMobile={isMobile} $isNotAbsolute={isMobile}>
      <FormContainer
        $error={!!errors[EMAIL_INPUT_ID]}
        $isMobile={isMobile}
        onSubmit={handleSubmit(formSubmit)}
      >
        <FormLabel
          htmlFor={EMAIL_INPUT_ID}
          $active={active}
          $error={!!errors[EMAIL_INPUT_ID]}
          $isEditing={isEditing}
          $isMobile={isMobile}
        >
          Enter your email address
        </FormLabel>
        <FormInput
          $isMobile={isMobile}
          id={EMAIL_INPUT_ID}
          type="email"
          {...register(EMAIL_INPUT_ID, {
            required: true,
            pattern: patterns.emailPattern,
          })}
          autoComplete="off"
          onFocus={() => setActive(true)}
          $error={!!errors[EMAIL_INPUT_ID]}
          onChange={changeInputHandler}
        />
        <ErrorMessage
          name={EMAIL_INPUT_ID}
          errors={errors}
          render={({ message }) => (
            <ErrorText $error={!!errors[EMAIL_INPUT_ID]}>
              <SmallWarningIcon color={theme.colors.red} />
              {message}
            </ErrorText>
          )}
        />
        {!isMobile && (
          <SubmitButtonContainer>
            <ActionButton
              iconColor={
                errors[EMAIL_INPUT_ID]
                  ? theme.colors.gray
                  : theme.colors.funtainBlue
              }
              backColor="none"
              borderColor="none"
              borderSize="sm"
              buttonSize="sm"
              disabled={!!errors[EMAIL_INPUT_ID] || !active}
              icon={SubmitIcon}
              isTypeSubmit
            />
          </SubmitButtonContainer>
        )}
      </FormContainer>
      <NextContainer>
        <SubmitButton
          buttonSize={isMobile ? 'sm' : 'md'}
          borderSize={isMobile ? 'xs' : 'md'}
          text="Next"
          fontSize="sm"
          clickHandler={() => formSubmit()}
          isDisabled={!!errors[EMAIL_INPUT_ID] || !active}
        />
      </NextContainer>
    </ChatActionsColContainer>
  );
};

export default Email;
