import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'router/models';

import { ListDataItem } from './helpers';
import { CorporateAudience, Images } from './types';

type CorporateContextType = {
  offerTerm: string;
  isValidCode: boolean;
  isChatSuccess: boolean;
  userName: string;
  codeValue: string;
  publicCode: string;
  images: Images;
  accountName: string;
  audience: CorporateAudience;
  department: ListDataItem | null;
  departments: ListDataItem[];
  isShowDepartment: boolean;
  setDepartment: (value: ListDataItem) => void;
  setDepartments: (value: ListDataItem[]) => void;
  setOfferTerm: (value: string) => void;
  onValidCode: (value: string) => void;
  onChatSuccess: () => void;
  setUserName: (value: string) => void;
  setPublicCode: (value: string) => void;
  setUserImages: (images: Images) => void;
  setAccountName: (name: string) => void;
  setAudience: (value: CorporateAudience) => void;
  setIsShowDepartment: (value: boolean) => void;
};

const initialValue: CorporateContextType = {
  publicCode: '',
  offerTerm: '',
  isValidCode: false,
  isChatSuccess: false,
  userName: '',
  codeValue: '',
  images: { logo: '', background: '' },
  accountName: '',
  audience: CorporateAudience.General,
  department: null,
  departments: [],
  isShowDepartment: false,
  setIsShowDepartment: () => null,
  setDepartment: () => null,
  setDepartments: () => null,
  setOfferTerm: () => null,
  onValidCode: () => null,
  onChatSuccess: () => null,
  setUserName: () => null,
  setPublicCode: () => null,
  setUserImages: () => null,
  setAccountName: () => null,
  setAudience: () => null,
};

const CorporateContext = createContext<CorporateContextType>(initialValue);

const CorporateContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [userName, setUserName] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [isChatSuccess, setIsChatSuccess] = useState(false);
  const [codeValue, setCodeValue] = useState('');
  const [offerTerm, setOfferTerm] = useState('');
  const [publicCode, setPublicCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [audience, setAudience] = useState<CorporateAudience>(
    CorporateAudience.General
  );
  const [departments, setDepartments] = useState<ListDataItem[]>([]);
  const [department, setDepartment] = useState<ListDataItem | null>(null);
  const [isShowDepartment, setIsShowDepartment] = useState(false);

  const [images, setImages] = useState<Images>({
    logo: '',
    background: '',
  });

  const navigate = useNavigate();

  const setUserImages = ({ logo, background }: Images) => {
    setImages({ logo, background });
  };

  const onValidCode = (code: string) => {
    setIsValidCode(true);
    setCodeValue(code);
    navigate(`${AppRoutes.CorporateBase}/${AppRoutes.CorporateChat}`);
  };

  const onChatSuccess = () => {
    setIsChatSuccess(true);
    navigate(`${AppRoutes.CorporateBase}/${AppRoutes.CorporateSuccess}`);
  };

  return (
    <CorporateContext.Provider
      value={{
        offerTerm,
        publicCode,
        isValidCode,
        isChatSuccess,
        userName,
        codeValue,
        images,
        accountName,
        audience,
        departments,
        department,
        isShowDepartment,
        setIsShowDepartment,
        setDepartment,
        setDepartments,
        setAccountName,
        setAudience,
        setPublicCode,
        setOfferTerm,
        onValidCode,
        onChatSuccess,
        setUserName,
        setUserImages,
      }}
    >
      {children}
    </CorporateContext.Provider>
  );
};

export default CorporateContextProvider;

export const useCorporateContext = () => useContext(CorporateContext);
