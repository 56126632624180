import React, { useEffect, useState } from 'react';

import SubmitButton from 'components/SubmitButton';
import { AnswerVariant } from 'modules/corporateOffer/types';

import ActionWarning from '../../components/ActionWarning';
import { ChatActionsContainer } from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

const choiceAnswerTexts = {
  [AnswerVariant.YesOrNo]: {
    first: 'Yes',
    second: 'No',
  },
  [AnswerVariant.TermsAndConditions]: {
    first: 'cancel',
    second: 'i agree',
  },
  [AnswerVariant.NotRecognizedAccount]: {
    first: 'CREATE NEW ACCOUNT',
    second: 'TRY AGAIN',
  },
  [AnswerVariant.NotRecognizedAccountMobile]: {
    first: 'NEW ACCOUNT',
    second: 'TRY AGAIN',
  },
  [AnswerVariant.NewOfferCode]: {
    first: 'KEEP',
    second: 'REPLACE',
  },
  [AnswerVariant.NewOfferCodeMobile]: {
    first: 'KEEP ACTIVE OFFER',
    second: 'REPLACE OFFER',
  },
};

type ChoiceAnswerProps = {
  isDisabled?: boolean;
  isMobile?: boolean;
  choiceType: AnswerVariant;
  isEditing?: boolean;
  withWarning?: boolean;
  textWarning?: string;
  onClickFirstHandler: () => void;
  onClickSecondHandler: () => void;
  onClickThirdHandler?: () => void;
};

const ChoiceAnswer = ({
  isDisabled,
  isMobile,
  textWarning,
  withWarning,
  choiceType,
  isEditing,
  onClickFirstHandler,
  onClickSecondHandler,
  onClickThirdHandler,
}: ChoiceAnswerProps) => {
  const { scrollChat } = useChatContext();

  const [showButtons, setShowButtons] = useState(!isMobile);

  const isAdditionalButtonShown =
    onClickThirdHandler && !showButtons && !isEditing;

  useEffect(() => {
    if (!onClickThirdHandler || isEditing) {
      setShowButtons(true);
    }
    scrollChat();
  }, [isEditing, onClickThirdHandler, scrollChat]);

  return (
    <ChatActionsContainer $isMobile={isMobile}>
      {withWarning && textWarning && (
        <ActionWarning isMobile={isMobile} text={textWarning} />
      )}
      {showButtons && (
        <>
          <SubmitButton
            buttonSize={isMobile ? 'sm' : 'md'}
            borderSize={isMobile ? 'xs' : 'md'}
            text={choiceAnswerTexts[choiceType].first}
            fontSize="sm"
            clickHandler={onClickFirstHandler}
          />
          <SubmitButton
            isDisabled={isDisabled}
            buttonSize={isMobile ? 'sm' : 'md'}
            borderSize={isMobile ? 'xs' : 'md'}
            text={choiceAnswerTexts[choiceType].second}
            fontSize="sm"
            clickHandler={onClickSecondHandler}
          />
        </>
      )}
      {isAdditionalButtonShown && (
        <SubmitButton
          buttonSize="sm"
          borderSize="xs"
          text="VIEW"
          fontSize="sm"
          clickHandler={() => {
            setShowButtons(true);
            onClickThirdHandler && onClickThirdHandler();
          }}
        />
      )}
    </ChatActionsContainer>
  );
};

export default ChoiceAnswer;
