import React, { SVGProps } from 'react';

import theme from 'theme/theme';

const OpenIcon = ({ width, height, color }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width || '23.5'}
    height={height || '23.5'}
    viewBox="0 0 23.5 23.5"
  >
    <defs>
      <clipPath id="clip-path-open-collapse">
        <path
          id="Path_2273"
          data-name="Path 2273"
          d="M12-.25A11.75,11.75,0,0,0,23.75-12,11.75,11.75,0,0,0,12-23.75,11.75,11.75,0,0,0,.25-12,11.764,11.764,0,0,0,12-.25Zm0-21A9.25,9.25,0,0,1,21.25-12,9.25,9.25,0,0,1,12-2.75,9.25,9.25,0,0,1,2.75-12,9.26,9.26,0,0,1,12-21.25Z"
          fill={color || theme.colors.darkBlueV2}
        />
      </clipPath>
      <clipPath id="clip-path-open-collapse-2">
        <path
          id="Path_2275"
          data-name="Path 2275"
          d="M6.273-10.75H10.5a.25.25,0,0,1,.25.25v4.227A1.25,1.25,0,0,0,12-5.023a1.25,1.25,0,0,0,1.25-1.25V-10.5a.25.25,0,0,1,.25-.25h4.227A1.25,1.25,0,0,0,18.977-12a1.25,1.25,0,0,0-1.25-1.25H13.5a.25.25,0,0,1-.25-.25v-4.228A1.25,1.25,0,0,0,12-18.978a1.25,1.25,0,0,0-1.25,1.25V-13.5a.25.25,0,0,1-.25.25H6.273A1.25,1.25,0,0,0,5.023-12a1.25,1.25,0,0,0,1.25,1.25Z"
          fill={color || theme.colors.darkBlueV2}
        />
      </clipPath>
    </defs>
    <g
      id="Group_56945"
      data-name="Group 56945"
      transform="translate(-0.25 23.75)"
    >
      <g
        id="Group_14933"
        data-name="Group 14933"
        clipPath="url(#clip-path-open-collapse)"
      >
        <path
          id="Path_2272"
          data-name="Path 2272"
          d="M-4.75-28.75h33.5V4.75H-4.75Z"
          fill={color || theme.colors.darkBlueV2}
        />
      </g>
      <g
        id="Group_14934"
        data-name="Group 14934"
        clipPath="url(#clip-path-open-collapse-2)"
      >
        <path
          id="Path_2274"
          data-name="Path 2274"
          d="M.023-23.978H23.977V-.023H.023Z"
          fill={color || theme.colors.darkBlueV2}
        />
      </g>
    </g>
  </svg>
);

export default OpenIcon;
