import React, { useState } from 'react';
import styled from 'styled-components';

import Arrow from 'components/Icons/Arrow';
import Play from 'components/Icons/Play';
import Player from 'components/player/Player';
import SectionTitle from 'modules/corporateOffer/components/sections/SectionTitle';
import theme from 'theme/theme';

type Props = {
  isMobile: boolean;
  videoSrc: string;
  videoText: string;
  goToNextSection: () => void;
};

const WellnessSection = ({
  isMobile,
  videoSrc,
  videoText,
  goToNextSection,
}: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Container>
      <SectionTitle>Discover connect wellness</SectionTitle>
      <Subtitle>
        {`Shido unites physical and mental health\nfor a balanced life - all in one powerful app`}
      </Subtitle>

      <VideoWrapper>
        <VideoBubble />
        <img
          src="/images/affiliateWellness.png"
          alt="Discover connect wellness"
        />
        <ButtonWrapper>
          <ButtonBorder>
            <PlayButton onClick={() => setIsPlaying((prev) => !prev)}>
              <PlayIcon color={theme.colors.white} />
            </PlayButton>
          </ButtonBorder>
        </ButtonWrapper>
        <VideoText role="button" onClick={() => setIsPlaying((prev) => !prev)}>
          {videoText}
        </VideoText>
      </VideoWrapper>

      <ArrowWrapper onClick={goToNextSection}>
        <Arrow />
      </ArrowWrapper>
      <Player
        src={videoSrc}
        isOpen={isPlaying}
        closeVideo={() => setIsPlaying(false)}
        isMobile={isMobile}
      />
    </Container>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: absolute;
  left: 0;
  right: 0;
`;

const ButtonBorder = styled.div`
  width: 100px;
  height: 100px;
  border: 10px solid rgba(255 255 255 / 50%);
  border-radius: 50%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 60px;
    height: 60px;
  }
`;

const PlayButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PlayIcon = styled(Play)`
  width: 33px;
  height: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 21px;
    height: 20px;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  height: 728px;
  width: 732px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  & img {
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 357px;
    height: 355px;
  }
`;

const VideoBubble = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  left: 0;
  width: 90%;
  height: 90%;
  max-width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  opacity: 0.15;
  border: 1px dashed ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`;

const VideoText = styled.p`
  position: absolute;
  bottom: -30px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 16px;
  font-weight: 700;
  line-height: 54px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    bottom: -40px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  margin-bottom: 34px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
  }
`;

const ArrowWrapper = styled.div`
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
`;

const Container = styled.section`
  max-width: 1400px;
  padding: 78px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px dashed rgba(65 78 113 / 25%);

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0;
  }
`;

export default WellnessSection;
