import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import {
  BubbleCircle,
  BubbleCircleContainer,
} from 'modules/corporateOffer/components/sections/HeroSection';

type Props = {
  title: string;
  image: string | null;
  isMobile?: boolean;
};

const HeroSection = ({ title, image, isMobile }: Props) => {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>

        <FirstBubble $usePct $left={-7} $top={32} $height={273} $width={273}>
          <BlueBubbleCircle />
        </FirstBubble>
        <SecondBubble $usePct $left={15} $top={60} $height={73} $width={73}>
          <BlueBubbleCircle />
        </SecondBubble>
        <ThirdBubble $usePct $right={-2} $top={28} $height={73} $width={73}>
          <BlueBubbleCircle />
        </ThirdBubble>
        <FourthBubble $usePct $right={14} $top={74} $height={138} $width={138}>
          <BlueBubbleCircle />
        </FourthBubble>
      </Container>
      <ImageGraphContainer>
        <ImageInnerContainer>
          <AdditionalImageInner>
            {image ? (
              <img src={image} />
            ) : (
              <LogoIcon
                width={isMobile ? 100 : 153}
                height={isMobile ? 50 : 93}
              />
            )}
          </AdditionalImageInner>
        </ImageInnerContainer>
      </ImageGraphContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 70px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.topicBlue};
  clip-path: ellipse(72% 91% at 50% 9%);
  position: relative;
  padding-bottom: 190px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    clip-path: ellipse(122% 83% at 50% 9%);
    overflow: hidden;
    padding-bottom: 150px;
  }
`;

const Title = styled.h1`
  font-size: 50px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  margin-top: 65px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 45px;
    line-height: 50px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 30px;
    line-height: 45px;
  }
`;

const ImageGraphContainer = styled.div`
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.colors.lightBlue};
  border-radius: 50%;
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 150px;
    height: 150px;
    bottom: -22%;
  }
`;

const ImageInnerContainer = styled.div`
  width: 270px;
  height: 270px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  background: white;
  border-radius: 50%;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 15px -4px grey;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 116px;
    height: 116px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const AdditionalImageInner = styled.div`
  width: 262px;
  height: 262px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 109px;
    height: 109px;
  }

  img {
    width: 268px;
    height: 268px;
    object-fit: cover;

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 116px;
      height: 116px;
    }
  }
`;

const BlueBubbleCircle = styled(BubbleCircle)`
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

const FirstBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    left: -20%;
    top: 50%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: -40%;
    top: 60%;
  }
`;

const SecondBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

const ThirdBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    right: -6%;
    top: 20%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -10%;
    top: 30%;
  }
`;

const FourthBubble = styled(BubbleCircleContainer)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    right: 6%;
    top: 70%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -4%;
    top: 75%;
  }
`;

export default HeroSection;
