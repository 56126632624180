import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import SubmitButton from 'components/SubmitButton';
import { CodeStatus } from 'modules/corporateOffer/types';

import ActionWarning from '../../components/ActionWarning';
import MobileKeyboard from '../../components/MobileKeyboard';
import { DeleteButton } from '../../components/styledElements/StyledButtons';
import {
  ChatActionsContainer,
  FormCodeContainer,
  FormInputCode,
} from '../../components/styledElements/StyledFormElements';
import { useChatContext } from '../ChatContext';

import { FIELDS } from './PinCode';

type PinCodeMobileProps = {
  errorText: string;
  goToNextStep: (value: string) => void;
  clickHandler?: () => void;
};

const PinCodeMobile = ({
  errorText,
  goToNextStep,
  clickHandler,
}: PinCodeMobileProps) => {
  const { scrollChat } = useChatContext();

  const { register, watch, setValue, setFocus, handleSubmit } = useForm();
  const [active, setActive] = useState(false);
  const [verifyCodeStatus, setVerifyCodeStatus] = useState<CodeStatus>(
    CodeStatus.Pending
  );
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    if (errorText) {
      FIELDS.forEach((field) => {
        setValue(field, '');
      });
      setActive(false);
      setCurrentIndex(1);
    }
  }, [errorText, setFocus, setValue]);

  const allFieldsValues = watch();

  const requestCondition = useMemo(() => {
    return FIELDS.every(
      (field) =>
        !isNaN(Number(allFieldsValues[field])) &&
        allFieldsValues[field] !== '' &&
        verifyCodeStatus !== CodeStatus.Valid
    );
  }, [verifyCodeStatus, allFieldsValues]);

  const buttonClick = useCallback(
    (number: number) => {
      if (currentIndex <= FIELDS.length) {
        setValue(String(currentIndex), number);

        if (verifyCodeStatus === CodeStatus.Invalid) {
          setVerifyCodeStatus(CodeStatus.Pending);
        }

        if (currentIndex === FIELDS.length) {
          setCurrentIndex(1);
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      }
    },
    [currentIndex, setValue, verifyCodeStatus]
  );

  const formSubmit = useCallback(() => {
    if (requestCondition) {
      const code = FIELDS.map((field) => watch()[field]).join('');

      goToNextStep(code);
    }
  }, [requestCondition, goToNextStep, watch]);

  useEffect(() => {
    scrollChat();
  }, [scrollChat]);

  useEffect(() => {
    if (requestCondition) {
      formSubmit();
    }
  }, [formSubmit, requestCondition]);

  return (
    <ChatActionsContainer $isMobile $isNotAbsolute={active}>
      <FormCodeContainer
        $isActive={active}
        $isMobile
        onSubmit={handleSubmit(formSubmit)}
      >
        {!active && (
          <SubmitButton
            buttonSize="sm"
            borderSize="xs"
            text="Enter pin"
            fontSize="sm"
            clickHandler={() => {
              setActive(true);
              setTimeout(() => {
                scrollChat();
              }, 100);
            }}
          />
        )}
        {active && (
          <>
            {FIELDS.map((field, i) => (
              <FormInputCode
                key={field}
                id={field}
                disabled={verifyCodeStatus === CodeStatus.Valid}
                type="text"
                autoComplete="off"
                {...register(field, {
                  required: false,
                })}
                $verificationCodeState={verifyCodeStatus}
                maxLength={1}
                $isMobile
                readOnly
                value={
                  !isNaN(allFieldsValues[field]) ? allFieldsValues[field] : ''
                }
                autoFocus={i === 0}
              />
            ))}
          </>
        )}
        {active && <MobileKeyboard buttonClick={buttonClick} />}
        {active && (
          <DeleteButton
            onClick={() => {
              setValue(String(currentIndex - 1), '');
              if (currentIndex > 1) {
                setCurrentIndex(currentIndex - 1);
              }
            }}
          >
            delete
          </DeleteButton>
        )}
      </FormCodeContainer>
      {!active && (
        <ActionWarning
          clickHandler={clickHandler}
          text={
            errorText
              ? errorText
              : 'Didn’t receive the PIN? Please allow 60 seconds for the email to arrive'
          }
          actionText={errorText ? '' : 'REQUEST A NEW PIN'}
          isMobile
        />
      )}
    </ChatActionsContainer>
  );
};

export default PinCodeMobile;
