import { useCallback, useRef } from 'react';

const useCreateRefsForClick = () => {
  const successRef = useRef<HTMLDivElement | null>(null);
  const checkoutRef = useRef<HTMLDivElement | null>(null);

  const handleRefClick = useCallback(
    (element: React.RefObject<HTMLDivElement>) => {
      return () => {
        if (element.current) {
          element.current?.scrollIntoView({ behavior: 'smooth' });
        }
      };
    },
    []
  );

  return {
    refs: [successRef, checkoutRef],
    handleRefClick,
  };
};

export default useCreateRefsForClick;
