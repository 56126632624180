import React, { useEffect } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import styled, { css } from 'styled-components';

import SlideSelector from 'modules/promotionOffer/components/SlideSelector';

import { SuccessCarouselData } from './carouselData';

const AUTOPLAY_INTERVAL = 6000;
const AMOUNT_OF_STARS = 5;

type Props = {
  isMobile: boolean;
  slides: SuccessCarouselData[];
};

const SuccessStoriesCarousel = ({ isMobile, slides }: Props) => {
  const { scrollRef, activePageIndex, pages, snapPointIndexes, goTo } =
    useSnapCarousel();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activePageIndex === pages.length - 1) {
        goTo(0);
      } else {
        goTo(activePageIndex + 1);
      }
    }, AUTOPLAY_INTERVAL);

    return () => {
      clearTimeout(timeout);
    };
  }, [activePageIndex, pages, goTo]);

  return (
    <Container>
      <Scroll ref={scrollRef}>
        {slides.map((item, i) => (
          <Content key={item.id} $isSnapPoint={snapPointIndexes.has(i)}>
            <StarRatingContainer>
              {Array.from(Array(AMOUNT_OF_STARS).keys()).map((number) => (
                <Star key={number} src="/star.svg" alt="star" />
              ))}
            </StarRatingContainer>
            <Text>{isMobile ? item.mobileText : item.text}</Text>
            <Image src={item.imageSrc} alt={item.name} />
            <Name>{item.name}</Name>
          </Content>
        ))}
      </Scroll>
      <SelectorContainer aria-hidden>
        <SlideSelector
          slides={slides}
          activeIdx={activePageIndex}
          interval={AUTOPLAY_INTERVAL}
          clickHandler={goTo}
        />
      </SelectorContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 33px;
  }
`;

const SelectorContainer = styled.div`
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 18px;
  }
`;

const Scroll = styled.ul`
  position: relative;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  box-sizing: border-box;
  overscroll-behavior: contain;
  height: 280px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 305px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div<{ $isSnapPoint: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  ${({ $isSnapPoint }) =>
    $isSnapPoint &&
    css`
      scroll-snap-align: start;
    `}

  p {
    text-align: center;
  }
`;

const StarRatingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 33px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-bottom: 13px;
  }
`;

const Star = styled.img`
  width: 24px;
  height: 24px;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const Image = styled.img`
  width: 61px;
  height: 61px;
  border-radius: 50%;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 41px;
    height: 41px;
    margin-top: 0;
    margin-bottom: 19px;
  }
`;

const Name = styled.p`
  font-size: 16px;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    line-height: 17px;
    margin: 0 auto;
  }
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 300;
  white-space: pre-line;
  line-height: 30px;
  padding-right: 1px;
  margin-bottom: 32px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 21px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 26px;
    width: 100vw;
    margin-top: 20px;
    white-space: normal;
    max-width: 340px;
  }
`;

export default SuccessStoriesCarousel;
