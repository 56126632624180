import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { isMobile } from 'helpers/getDeviceInfo';
import AffiliateFooter from 'modules/affiliateOffer/components/AffiliateFooter';
import WellnessSection from 'modules/affiliateOffer/components/WellnessSection';

import { useCorporateContext } from '../CorporateContext';
import { selectMonthPeriod } from '../helpers';
import { CorporateAudience } from '../types';

import BroughtBySection from './sections/BroughtBySection';
import FAQSection from './sections/FAQSection';
import HeroSection from './sections/HeroSection';
import PricingSection from './sections/PricingSection';
import SuccessStoriesSection from './sections/SuccessStoriesSection';
import StickyHeader from './StickyHeader';

const OfferIntroContent = ({ isLoading }: { isLoading: boolean }) => {
  const { offerTerm, images, accountName, isShowDepartment, audience } =
    useCorporateContext();

  const [isExpired, setIsExpired] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const faqRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);

  const isEmployee = audience === CorporateAudience.Employee;
  const monthPeriod = selectMonthPeriod(offerTerm);

  return (
    <Container>
      <StickyHeader />
      <HeroSection
        monthPeriod={monthPeriod}
        isLoadingData={isLoading}
        isExpired={isExpired}
        setIsExpired={() => setIsExpired(true)}
        isSuspended={isSuspended}
        setIsSuspended={() => setIsSuspended(true)}
        notFound={!isLoading && !offerTerm}
        withDepartments={isShowDepartment}
        onTryForFree={() =>
          pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <BroughtBySection accountName={accountName} logo={images.logo} />
      <WellnessSection
        isMobile={isMobile}
        videoSrc={
          isEmployee
            ? '/Shido_Explainer_Employee.mp4'
            : '/Shido_Explainer_Consumer.mp4'
        }
        videoText={
          isEmployee ? 'For employees: 1.43 min' : 'How Shido works : 1.34 min'
        }
        goToNextSection={() => {
          if (isEmployee) {
            faqRef.current?.scrollIntoView({ behavior: 'smooth' });
          } else {
            successRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      />
      {isEmployee && (
        <FAQSection
          ref={faqRef}
          goToNextSection={() =>
            successRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
        />
      )}
      <SuccessStoriesSection
        ref={successRef}
        title="Trusted by 1000’s of people like you"
        withFooter={isExpired || isSuspended}
        withSubtitle={false}
      />
      <div ref={pricingRef}>
        {(isExpired || isSuspended) && <PricingSection />}
      </div>
      <AffiliateFooter />
    </Container>
  );
};

const Container = styled.main`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

export default OfferIntroContent;
