import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import ActionButton from 'components/ActionButton';
import PlayIcon from 'components/Icons/PlayIcon';
import Player from 'components/player/Player';
import theme from 'theme/theme';

type Props = {
  isMobile?: boolean;
};

const ShidoDescription = ({ isMobile }: Props) => {
  const [isOpen, toggleIsOpen] = useState(false);

  return (
    <>
      <Slogan $isMobile={isMobile}>
        Helping you be your best & achieve optimal health.
      </Slogan>
      {!isMobile && (
        <>
          <ShidoExplanation>
            Shido connects all aspects of your health in a single place, from
            nutrition to motivation & condition management, we have an entire
            team dedicated to providing you with advice & inspiration targeted
            to your specific needs, interests & goals.
          </ShidoExplanation>
          <SeeHow>see how</SeeHow>
        </>
      )}
      <PlayButton>
        <ActionButton
          iconColor={theme.colors.white}
          backColor={theme.colors.funtainBlue}
          borderColor={theme.colors.white}
          borderSize={isMobile ? 'md' : 'lg'}
          clickHandler={() => toggleIsOpen(true)}
          withShadow
          buttonSize={isMobile ? 'md' : 'lg'}
          icon={PlayIcon}
        />
      </PlayButton>
      <Player
        src="https://videos.ctfassets.net/m61lhx9gr8sv/D2dJUCu7L43t9HIFiW7TL/e85dc42dc758c1435d8a9288083e7d03/SHIDO_INTRODUCTION_ENG_V6_COMPRESSED.mp4"
        isMobile={isMobile}
        isOpen={isOpen}
        closeVideo={() => toggleIsOpen(false)}
      />
    </>
  );
};

const Slogan = styled.h3<{ $isMobile?: boolean }>`
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '20px')};
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 12px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 280px;
      margin: 0 auto 16px;
      line-height: 20px;
    `}
`;

const ShidoExplanation = styled.h3`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 740px;
  margin: 0 auto 30px;
  line-height: 30px;
  padding: 0 10px;
`;

const PlayButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 17px 0;
`;

const SeeHow = styled.p`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
`;

export default ShidoDescription;
