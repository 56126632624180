import React from 'react';
import styled from 'styled-components';

import OfferLogo from '../OfferLogo';

type Props = {
  accountName: string;
  logo?: string;
};

const BroughtBySection = ({ accountName, logo }: Props) => {
  return (
    <Container>
      <Text>Brought to you by {accountName || 'Shido'}</Text>
      <OfferLogo accountName={accountName} src={logo} />
    </Container>
  );
};

const Container = styled.section`
  max-width: 1400px;
  padding: 78px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px dashed rgba(65 78 113 / 25%);

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0;
  }
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  margin-bottom: 34px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
    margin-bottom: 0;
    padding: 0 20px;
  }
`;

export default BroughtBySection;
