import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import SubmitButton from 'components/SubmitButton';
import StandardInput from 'modules/corporateOffer/components/inputs/StandardInput';

import { useCorporateContext } from '../CorporateContext';
import { ListDataItem } from '../helpers';
import { validateOfferCode } from '../services';

import StandardDropDown from './dropdown/DropDownInput';

enum QueryKey {
  GetOfferCode = 'GET_OFFER_CODE',
}

const OFFER_CODE_INPUT_ID = 'OFFER_CODE_INPUT_ID';

const DEPARTMENT_DROPDOWN_INPUT_ID = 'DEPARTMENT_DROPDOWN_INPUT_ID';

const NOT_VALID_ERROR = 'NOT_VALID_ERROR';
const REQUEST_FROM_FORM = 'REQUEST_FROM_FORM';

type Props = {
  isMobile?: boolean;
  onLoading?: (isLoading: boolean) => void;
  setPageToExpired: () => void;
  setPageToSuspended: () => void;
};

const OfferCodeForm = ({
  isMobile,
  onLoading,
  setPageToExpired,
  setPageToSuspended,
}: Props) => {
  const {
    publicCode,
    departments,
    isShowDepartment,
    setDepartment,
    onValidCode,
  } = useCorporateContext();

  const [active, setActive] = useState(false);

  const {
    register,
    setValue,
    setFocus,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({ shouldFocusError: false });
  const offerCodeValue: string = watch()[OFFER_CODE_INPUT_ID] || '';
  const departmentValue = watch()[DEPARTMENT_DROPDOWN_INPUT_ID] || '';

  const { refetch } = useQuery(
    [QueryKey.GetOfferCode, REQUEST_FROM_FORM, offerCodeValue],
    () =>
      validateOfferCode({
        publicCode,
        offerCode: offerCodeValue,
      }),
    {
      onSuccess(data) {
        if (data) {
          if (data.succeeded) {
            onValidCode(offerCodeValue);
          } else if (data.message.toLowerCase().includes('expired')) {
            setPageToExpired();
          } else if (data.message.toLowerCase().includes('suspended')) {
            setPageToSuspended();
          } else {
            setError(OFFER_CODE_INPUT_ID, {
              message: 'Offer code not recognized',
              type: NOT_VALID_ERROR,
            });
          }
        }
      },
      onSettled: () => {
        onLoading?.(false);
      },
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
    }
  );

  const setUpDepartment = useCallback(
    (id: string) => {
      const department = departments.find(
        (item) => item.id === id
      ) as ListDataItem;

      setValue(DEPARTMENT_DROPDOWN_INPUT_ID, department.displayName || '', {
        shouldValidate: true,
      });
      setDepartment(department);
    },
    [departments, setDepartment, setValue]
  );

  const formSubmit = async () => {
    if (!errors[OFFER_CODE_INPUT_ID]) {
      onLoading?.(true);
      refetch();
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit(formSubmit)}>
      {isShowDepartment && (
        <StandardDropDown
          isMobile={isMobile}
          chosenText={departmentValue}
          data={departments}
          inputId={DEPARTMENT_DROPDOWN_INPUT_ID}
          textLabel="Select your department"
          errors={errors}
          setChosenText={setUpDepartment}
          register={register}
        />
      )}
      <StandardInput
        setActive={() => setActive(true)}
        active={active}
        isMobile={isMobile}
        inputId={OFFER_CODE_INPUT_ID}
        value={offerCodeValue}
        label="Enter offer code"
        errors={errors}
        setFocus={setFocus}
        register={register}
        setValue={setValue}
        enterValue={(value) =>
          setValue(OFFER_CODE_INPUT_ID, value, { shouldValidate: true })
        }
      />
      {active && errors[OFFER_CODE_INPUT_ID]?.type !== NOT_VALID_ERROR && (
        <SubmitButtonWrapper $withDepartments={isShowDepartment}>
          <SubmitButton
            isDisabled={!_.isEmpty(errors)}
            buttonSize={isMobile ? 'sm' : 'xl'}
            fontSize={isMobile ? 'sm' : 'md'}
            borderSize={isMobile ? 'sm' : 'xl'}
            text="continue"
          />
        </SubmitButtonWrapper>
      )}
    </FormContainer>
  );
};

const FormContainer = styled.form`
  position: relative;
`;

const SubmitButtonWrapper = styled.div<{ $withDepartments: boolean }>`
  position: absolute;
  bottom: ${({ $withDepartments }) => ($withDepartments ? '-106px' : '-200px')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: ${({ $withDepartments }) =>
      $withDepartments ? '-112px' : '-200px'};
  }
`;

export default OfferCodeForm;
