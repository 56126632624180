import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import theme from 'theme/theme';

const AffiliateFooter = () => {
  return (
    <Container>
      <LogoIcon width={70} height={44} fill={theme.colors.white} />
    </Container>
  );
};

const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.topicBlue};
  display: flex;
  justify-content: center;
  padding: 78px 0 200px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 34px 0 100px;
  }
`;

export default AffiliateFooter;
